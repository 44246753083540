<template>
  <v-sheet>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title @click="addWidth()">
              Kitchen {{ value.x }}
            </v-card-title>
            <v-card-text>
              <v-autocomplete label="Search"></v-autocomplete>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="pa-4">
            <v-row>
              <v-col cols="6">
                <qrcode-stream
                  v-if="cameraOn"
                  :key="_uid"
                  :track="selected.value"
                  @init="logErrors"
                />
              </v-col>
              <v-col cols="6">
                <v-btn @click="cameraOn = !cameraOn"
                  ><v-icon>mdi-camera</v-icon></v-btn
                >
                <h3>{{ output }}</h3>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import * as Utils from "../classes/classes";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: { QrcodeStream },
  name: "KitchenInventory",
  data: function () {
    return {
      value: new Utils.Rectangle(2, 3),
      cameraOn: false,
      groups: [
        {
          name: "Hoffman",
          startDate: "April 17, 2022",
          endDate: "April 24, 2022",
        },
        {
          name: "Buddhist Group",
          startDate: "April 30, 2022",
          endDate: "May 2, 2022",
        },
        {
          name: "Hoffman",
          startDate: "April 17, 2022",
          endDate: "April 24, 2022",
        },
        {
          name: "Buddhist Group",
          startDate: "April 30, 2022",
          endDate: "May 2, 2022",
        },
        {
          name: "Hoffman",
          startDate: "April 17, 2022",
          endDate: "April 24, 2022",
        },
        {
          name: "Buddhist Group",
          startDate: "April 30, 2022",
          endDate: "May 2, 2022",
        },
      ],
      options: [
        { text: "nothing (default)", value: undefined },
        { text: "outline", value: this.paintOutline },
        { text: "centered text", value: this.paintCenterText },
        { text: "bounding box", value: this.paintBoundingBox },
      ],
      selected: { text: "centered text", value: this.paintCenterText },
      output: [],
    };
  },
  methods: {
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;
        ctx.strokeStyle = "red";
        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#007bff";
        ctx.strokeRect(x, y, width, height);
      }
    },
    paintCenterText(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox, rawValue } = detectedCode;
        const centerX = boundingBox.x + boundingBox.width / 2;
        const centerY = boundingBox.y + boundingBox.height / 2;
        const fontSize = Math.max(
          12,
          (50 * boundingBox.width) / ctx.canvas.width
        );
        console.log(boundingBox.width, ctx.canvas.width);
        ctx.font = `bold ${fontSize}px sans-serif`;
        ctx.textAlign = "center";
        ctx.lineWidth = 3;
        ctx.strokeStyle = "#ffffff";
        ctx.strokeText(detectedCode.rawValue, centerX, centerY);
        ctx.fillStyle = "#ffffff";
        ctx.fillText(rawValue, centerX, centerY);
        if (!this.output.includes(rawValue)) this.output.push(rawValue);
      }
    },
    logErrors(promise) {
      promise.catch(console.error);
    },
    addWidth() {
      this.value.addWidthFunction(1);
    },
  },
};
</script>
